import '@fontsource/poppins';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/vollkorn';
import '@fontsource/vollkorn/500.css';
import '@fontsource/vollkorn/600.css';
import Prism from 'prism-react-renderer/prism';

(typeof global !== 'undefined' ? global : window).Prism = Prism;
require('prismjs/components/prism-css');
require('prismjs/components/prism-yaml');
require('prismjs/components/prism-jsx');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-json');
require('prismjs/components/prism-markdown');
require('prismjs/components/prism-qsharp');
require('prismjs/components/prism-regex');
require('prismjs/components/prism-scss');
require('prismjs/components/prism-tsx');
require('prismjs/components/prism-typescript');
require('prismjs/components/prism-http');
require('prismjs/components/prism-graphql');
require('prismjs/components/prism-cshtml');
require('prismjs/components/prism-git');
