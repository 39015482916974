exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-snippets-jsx": () => import("./../../../src/pages/snippets.jsx" /* webpackChunkName: "component---src-pages-snippets-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-page-jsx-content-file-path-src-blog-pages-about-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/blog/pages/about.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-blog-pages-about-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-blog-pages-privacy-policy-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/blog/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-blog-pages-privacy-policy-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-6-useful-tips-on-how-to-write-clean-html-and-css-code-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/6-useful-tips-on-how-to-write-clean-html-and-css-code/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-6-useful-tips-on-how-to-write-clean-html-and-css-code-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-build-a-reusable-searchable-list-in-react-with-typescript-generics-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/build-a-reusable-searchable-list-in-react-with-typescript-generics/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-build-a-reusable-searchable-list-in-react-with-typescript-generics-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-the-react-useeffect-works-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-the-react-useeffect-works/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-the-react-useeffect-works-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-add-query-parameters-to-the-url-in-nextjs-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-add-query-parameters-to-the-url-in-nextjs/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-add-query-parameters-to-the-url-in-nextjs-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-create-a-collapsible-panel-with-smooth-animation-in-react-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-create-a-collapsible-panel-with-smooth-animation-in-react/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-create-a-collapsible-panel-with-smooth-animation-in-react-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-create-dynamic-routing-in-gatsby-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-create-dynamic-routing-in-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-create-dynamic-routing-in-gatsby-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-draw-images-in-charts-labels-in-react-chartjs-2-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-draw-images-in-charts-labels-in-react-chartjs-2/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-draw-images-in-charts-labels-in-react-chartjs-2-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-revalidate-server-components-in-nextjs-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-revalidate-server-components-in-nextjs/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-revalidate-server-components-in-nextjs-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-use-external-images-from-dropbox-in-gatsby-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/how-to-use-external-images-from-dropbox-in-gatsby/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-how-to-use-external-images-from-dropbox-in-gatsby-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-new-routing-in-nextjs-app-router-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/new-routing-in-nextjs-app-router/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-new-routing-in-nextjs-app-router-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-scalable-css-layout-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/scalable-css-layout/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-scalable-css-layout-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-what-are-the-usememo-and-usecallback-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/what-are-the-usememo-and-usecallback/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-what-are-the-usememo-and-usecallback-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-articles-what-is-maintainable-css-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/articles/what-is-maintainable-css/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-articles-what-is-maintainable-css-index-mdx" */),
  "component---src-templates-post-jsx-content-file-path-src-blog-snippets-usefetch-hook-for-fetching-api-index-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/build/repo/src/blog/snippets/usefetch-hook-for-fetching-api/index.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-src-blog-snippets-usefetch-hook-for-fetching-api-index-mdx" */),
  "component---src-templates-posts-jsx": () => import("./../../../src/templates/posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-snippet-category-jsx": () => import("./../../../src/templates/snippetCategory.jsx" /* webpackChunkName: "component---src-templates-snippet-category-jsx" */),
  "slice---src-layouts-footer-index-jsx": () => import("./../../../src/layouts/Footer/index.jsx" /* webpackChunkName: "slice---src-layouts-footer-index-jsx" */),
  "slice---src-layouts-header-index-jsx": () => import("./../../../src/layouts/Header/index.jsx" /* webpackChunkName: "slice---src-layouts-header-index-jsx" */)
}

